import request from '@/utils/request'

export function weChatlogin(data) {
    return request({
        url: '/api/user/weChatlogin',
        method: 'post',
        data
    })
}

export function login(data) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data
    })
}



export function visitorLogin(data) {
    return request({
        url: '/api/user/visitorLogin',
        method: 'post',
        data
    })
}


export function phoneLogin(data) {
    return request({
        url: '/api/user/phoneLogin',
        method: 'post',
        data
    })
}


/**
 * 获取聚合登录地址
 * @param {} data 
 * @returns 
 */
export function getScanLogin(data) {
    return request({
        url: '/api/user/getScanLogin',
        method: 'post',
        data
    })
}


/**
 * 聚合登录
 * @param {} data 
 * @returns 
 */
export function scanLogin(data) {
    return request({
        url: '/api/user/scanLogin',
        method: 'post',
        data
    })
}


export function logout() {
    return request({
        url: '/api/user/logout',
        method: 'post'
    })
}

export function register(data) {
    return request({
        url: '/api/user/register',
        method: 'post',
        data
    })
}

//微信公众号注册登录
export function registerWeChat(data) {
    return request({
        url: '/api/user/registerWeChat',
        method: 'post',
        data
    })
}

export function phoneRegister(data) {
    return request({
        url: '/api/user/phoneRegister',
        method: 'post',
        data
    })
}


/**
 * 绑定账号
 * @param {} data 
 * @returns 
 */
export function bindAccount(data) {
    return request({
        url: '/api/user/bindAccount',
        method: 'post',
        data
    })
}


/**
 * 创建账号
 * @param {} data 
 * @returns 
 */
export function createAccount(data) {
    return request({
        url: '/api/user/createAccount',
        method: 'post',
        data
    })
}






export function balance() {
    return request({
        url: '/api/user/transaction/balance',
        method: 'post'
    })
}

export function info() {
    return request({
        url: '/api/user/info',
        method: 'post'
    })
}

/**
 * 生成用户密钥
 * @returns 
 */
export function generateKey(data) {
    return request({
        url: '/api/generate_key',
        method: 'post',
        data
    })
}


//个人中心--邮箱绑定验证码
export function verify_code(data) {
    return request({
        url: '/api/user/email/update/verify_code',
        method: 'post',
        data
    })
}
// 个人中心--邮箱绑定
export function email_update(data) {
    return request({
        url: '/api/user/email/update',
        method: 'post',
        data
    })
}
//个人中心--邮箱解除绑定验证码
export function email_code(data) {
    return request({
        url: '/api/user/email/remove/verify_code',
        method: 'post',
        data
    })
}

//个人中心--邮箱解除绑定
export function email_move(data) {
    return request({
        url: '/api/user/email/remove',
        method: 'post',
        data
    })
}

//个人中心--手机绑定验证码
export function phone_code(data) {
    return request({
        url: '/api/user/phone/update/verify_code',
        method: 'post',
        data
    })
}

//个人中心--手机号绑定
export function phone_update(data) {
    return request({
        url: '/api/user/phone/update',
        method: 'post',
        data
    })
}

//个人中心--手机解除绑定验证码
export function phone_remove_code(data) {
    return request({
        url: '/api/user/phone/remove/verify_code',
        method: 'post',
        data
    })
}

//个人中心--手机号解除绑定
export function phone_remove(data) {
    return request({
        url: '/api/user/phone/remove',
        method: 'post',
        data
    })
}
//个人中心--找回密码验证码获取（兼容手机、邮箱）
export function pwd_code(data) {
    return request({
        url: '/api/user/pwd_reset/verify_code',
        method: 'post',
        data
    })
}

//个人中心--实名认证
export function card_auth(data) {
    return request({
        url: '/api/user/card_auth',
        method: 'post',
        data
    })
}


//找回密码（重置密码）
export function pwd_reset(data) {
    return request({
        url: '/api/user/pwd/update',
        method: 'post',
        data
    })
}

//
export function pwdReset(data) {
    return request({
        url: '/api/user/pwd_reset',
        method: 'post',
        data
    })
}
export function invited_list(data) {
    return request({
        url: '/api/user/invited/list',
        method: 'post',
        data
    })
}


export function invitedStatis(data) {
    return request({
        url: '/api/user/invited/statis',
        method: 'post',
        data
    })
}

export function avatar(data) {
    return request({
        url: '/api/user/avatar/update',
        method: 'post',
        data
    })
}


/**
 * 获取手机验证码
 */
export function sendPhoneCode(data) {
    return request({
        url: '/api/user/sendPhoneCode',
        method: 'post',
        data
    })
}

/**
 * 短信登录
 */
// export function smslogin(data) {
//     return request({
//         url: '/api/user/smslogin',
//         method: 'post',
//         data
//     })
// }



/**
 * 查询用户等级列表
 */
export function getMemberLevelList(data) {
    return request({
        url: '/api/member_level/list',
        method: 'post',
        data
    })
}


/**
 * 用户自助升级
 */
export function userUpgradation(data) {
    return request({
        url: '/api/user/user_upgradation',
        method: 'post',
        data
    })
}